<template>
  <div class="bg-gray-900 min-h-screen flex items-center justify-center">
    <div class="bg-gray-800 shadow-md rounded-lg p-8 w-full max-w-md">
      <div class="flex justify-center mb-6 bg-gray-800 rounded-full">
        <img :src="logo" alt="Logo" class="w-24">
      </div>
      <h2 class="text-2xl font-semibold text-center text-white mb-4">Admin Login</h2>
      <form @submit.prevent="login">
        <div class="mb-4">
          <label for="email" class="block text-gray-300 mb-2">Email Address</label>
          <input type="email" v-model="email" id="email" placeholder="Enter your email"
            class="bg-gray-700 text-white border border-gray-600 w-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
            required>
        </div>
        <div class="mb-4 relative">
          <label for="password" class="block text-gray-300 mb-2">Password</label>
          <div class="relative flex items-center">
            <input :type="showPassword ? 'text' : 'password'" v-model="password" id="password"
              placeholder="Enter your password"
              class="bg-gray-700 text-white border border-gray-600 w-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10 rounded-lg"
              required>
            <span @click="togglePassword" class="absolute right-3 flex items-center text-gray-400 cursor-pointer"
              v-html="passwordIcon"></span>
          </div>
        </div>
        <div class="flex items-center justify-between mb-4">
          <div class="flex items-center">
            <input type="checkbox" v-model="remember" id="remember" class="text-blue-600 focus:ring-blue-500 h-4 w-4">
            <label for="remember" class="ml-2 text-gray-300">Remember Me</label>
          </div>
          <a href="#" class="text-sm text-blue-400 hover:underline">Forgot Password?</a>
        </div>
        <button type="submit" :disabled="isLoading"
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-full flex items-center justify-center">
          <span v-if="!isLoading">Login</span>
          <div v-else v-html="loaderIcon"></div>
        </button>
      </form>
      <div v-if="errorMessage" class="text-red-500 text-center mt-4">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { router } from '@inertiajs/vue3'
import feather from 'feather-icons';
import logo from '@/assets/img/logo.png';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const showPassword = ref(false);
    const remember = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref('');

    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    const login = () => {
      isLoading.value = true;
      errorMessage.value = '';

      router.visit('/login', {
        method: 'post',
        data: {
          email: email.value,
          password: password.value,
          remember: remember.value,
        },
        onStart: visit => {
          // You can do something when the request starts if needed
        },
        onFinish: visit => {
          isLoading.value = false;
        },
        onSuccess: page => {
          Toastify({
            text: "Login successful!",
            backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
            duration: 3000,
            position: 'center',
            close: true,
          }).showToast();

          // Redirect after successful login
          window.location.href = '/dashboard';
        },
        onError: errors => {
          errorMessage.value = errors.email || errors.password || 'An error occurred.';
          Toastify({
            text: errorMessage.value,
            backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
            duration: 3000,
            close: true,
            position: 'center',
          }).showToast();
        },
      });
    };


    const passwordIcon = computed(() => {
      return feather.icons[showPassword.value ? 'eye-off' : 'eye'].toSvg({ width: 24, height: 24 });
    });

    const loaderIcon = computed(() => {
      return feather.icons.loader.toSvg({ width: 24, height: 24, class: 'animate-spin' });
    });

    return {
      email,
      password,
      showPassword,
      remember,
      isLoading,
      errorMessage,
      togglePassword,
      login,
      logo,
      passwordIcon,
      loaderIcon,
    };
  },
};
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.toast-custom {
  border-radius: 9999px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  backdrop-filter: blur(10px);
  color: white;
}
</style>
